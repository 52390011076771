import React, { useState, useEffect, createContext, useContext } from "react"
import { getTheme } from "../helpers/brand.helper"

const ThemeContext = createContext(null)

// const themes = {
//   paypossible: {
//     themeColor: "#19C27F",
//     headerLogo: "/logos/paypossible_logo_green.png",
//     menuLogo: "/logos/paypossible_logo_green.png",
//     merchantLogo: "/PayPossible_Logo_Vert.png",
//     poweredByLogo: "/paypossible_black.png",
//     activeTab: "orders",
//     merchantColor: "#19C27F",
//     colors: {
//       green: "#19C27F",
//       blue: "#6564DB",
//       red: "#DB5461",
//       orange: "#FA9F42",
//       yellow: "#FCBF49",
//     },
//   },
// }

export function ThemeProvider({ children }) {
  // const key = "paypossible"
  // const activeTheme = themes[key]
  const activeTheme = getTheme()

  const [themeColor, setThemeColor] = useState(
    activeTheme?.themeColor || "#19C27F"
  )
  const [headerLogo, setHeaderLogo] = useState(
    activeTheme?.img || "/logos/paypossible_logo_green.png"
  )
  const [menuLogo, setMenuLogo] = useState(
    activeTheme?.img || "/logos/paypossible_logo_green.png"
  )
  const [merchantLogo, setMerchantLogo] = useState(
    activeTheme?.img || "/PayPossible_Logo_Vert.png"
  )
  const [poweredByLogo, setPoweredByLogo] = useState(
    activeTheme?.dark || "/paypossible_black.png"
  )
  const [themeLander, setThemeLander] = useState(activeTheme?.title || "")
  const [merchantColor, setMerchantColor] = useState(
    activeTheme?.color || "#19C27F"
  )
  const [colors, setColors] = useState({
    green: "#19C27F",
    blue: "#6564DB",
    red: "#DB5461",
    orange: "#FA9F42",
    yellow: "#FCBF49",
  })
  const [brandLabel, setBrandLabel] = useState(activeTheme?.label || "")

  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const bgColor = false ? "#ffffff" : "#fafafa"
  const isMobile = width <= 768
  const [viewHeight, setViewHeight] = useState(
    window.innerHeight - 24 - 24 - 18 - 60 - (isMobile ? 18 : 48)
  )
  const [isShowMenu, setIsShowMenu] = useState(true)

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth))
    window.addEventListener("resize", () => setHeight(window.innerHeight))
    window.addEventListener("resize", () =>
      setViewHeight(
        window.innerHeight - 24 - 24 - 18 - 60 - (isMobile ? 18 : 48)
      )
    )
  }, [isMobile])

  return (
    <ThemeContext.Provider
      value={{
        viewHeight,
        setMerchantLogo,
        merchantLogo,
        colors,
        setColors,
        setMerchantColor,
        merchantColor,
        activeTheme,
        themeLander,
        setThemeLander,
        themeColor,
        setThemeColor,
        headerLogo,
        setHeaderLogo,
        menuLogo,
        setMenuLogo,
        poweredByLogo,
        setPoweredByLogo,
        brandLabel,
        setBrandLabel,
        width,
        height,
        bgColor,
        isMobile,
        isShowMenu,
        setIsShowMenu,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
