import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Button, Row, Alert } from "antd"
import _ from "underscore"

import { useOffer } from "../hooks/hooks"
import { useAuth } from "../hooks/useAuth"
import { useTheme } from "../hooks/useTheme"

import { Spinner } from "../components/core/Spinner"
import GenesisOffer from "../components/lenders/GenesisOffer"
import { useApply } from "../hooks/useApply"
import FortivaOffer from "../components/lenders/FortivaOffer"
import CitizensOffer from "../components/lenders/CitizensOffer"
import OwnLeaseOffer from "../components/lenders/OwnLeaseOffer"
import UownOffer from "../components/lenders/UownOffer"
import FlexshopperOffer from "../components/lenders/FlexshopperOffer"
import CitiOffer from "../components/lenders/CitiOffer"
import PaypalOffer from "../components/lenders/PaypalOffer"
import OfferSummary from "../components/offer/OfferSummary"
import AffFinwiseSummaryModal from "../components/offer/AffFinwiseSummaryModal"
import SingleOfferCard from "../components/cards/SingleOfferCard"
import AffFinwiseOffer from "../components/offer/AffFinwiseOffer"
// import { ProductSelectLander } from "../components/landers"
// import { ProductCard } from "../components/cards"

// import { copyLead, updateLead } from "../services/lead.service"
import copy from "../utils/appCopy"

function OfferPage() {
  const { user } = useAuth()
  const history = useHistory()
  const { merchant, getLead, lead, merchantId } = useApply()
  const { setIsShowMenu } = useTheme()
  const { offer_id } = useParams()
  const { error, loading, data } = useOffer(offer_id)

  const [showModal, setShowModal] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  // const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  // const [isShowProduct, setIsShowProduct] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!error && (!merchant || !lead)) {
      if (data) {
        ;(async () => {
          await getLead(data.lead?.id)
        })()
      }
    }
  }, [data, error, getLead, lead, merchant])

  // useEffect(() => {
  //   if (!lead?.cart && merchant?.selectable_product) {
  //     setIsOpenConfirmModal(true)
  //   }
  // }, [merchant, lead])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])
  useEffect(() => {
    if (merchantId === "tireagent-flexshopper") {
      setIsShowMenu(false)
    }
    //eslint-disable-next-line
  }, [merchantId])

  useEffect(() => {
    if (data?.status) {
      const { id, status } = data
      window.parent?.postMessage({ type: "offer", id, status }, "*")
    }
    //eslint-disable-next-line
  }, [data?.status])

  const handleAcceptOffer = async () => {
    if (data.originator.key === "fortiva" && !isSubmit) return
    if (_.has(copy, data.originator.key)) {
      history.push(`/offers/${offer_id}/apply`)
    } else {
      const jwt = sessionStorage.getItem("jwt")
      const url = jwt ? `${data.apply_url}?token=${jwt}` : data.apply_url
      window.location.assign(url)
    }
  }

  // const selectProduct = () => {
  //   setIsOpenConfirmModal(false)
  //   setIsShowProduct(true)
  // }

  // const handleNewProduct = async (item) => {
  //   setIsShowProduct(false)

  //   if (item && item?.id !== lead?.product?.id) {
  //     const cLead = await copyLead(lead.id, lead)
  //     if (cLead.success) {
  //       let newLead = { ...cLead.data }
  //       delete newLead.product
  //       const { amount, code, description, id, price, purpose, quantity, url } =
  //         item
  //       newLead.product = {
  //         amount,
  //         code,
  //         description,
  //         id,
  //         price,
  //         purpose,
  //         quantity,
  //         url,
  //       }
  //       const nLead = await updateLead(newLead.id, newLead)
  //       if (nLead.success) {
  //         history.push(`/apps/${nLead.data.id}`)
  //       }
  //     }
  //   }
  // }

  if (error) {
    if (error?.response?.status === 404) {
      history.push("/404")
    }
  }

  if (isLoading || !data || !user || !merchant) {
    return <Spinner />
  }

  // if (isShowProduct) {
  //   return (
  //     <ProductSelectLander
  //       img={merchantLogo}
  //       merchant={merchant}
  //       callback={handleNewProduct}
  //     />
  //   )
  // }

  let component = null

  const acceptButton = (
    <Button type="primary" onClick={handleAcceptOffer}>
      {data.originator.key === "genesis" || data.originator.key === "fortiva"
        ? "Accept Offer"
        : "Apply Now"}
    </Button>
  )

  switch (data.originator.key) {
    case "genesis":
      component = (
        <GenesisOffer
          acceptOffer={handleAcceptOffer}
          offer={data}
          merchant={merchant}
          user={user}
        />
      )
      break
    case "finwise":
      component = (
        <AffFinwiseOffer offer={data} user={user} acceptButton={acceptButton} />
      )

      break
    case "americanfirst":
      component = (
        <AffFinwiseOffer offer={data} user={user} acceptButton={acceptButton} />
      )
      break
    case "fortiva":
      component = (
        <FortivaOffer
          offer={data}
          user={user}
          acceptOffer={handleAcceptOffer}
          setIsSubmit={setIsSubmit}
        />
      )
      break
    case "citizens":
      component = (
        <CitizensOffer
          offer={data}
          user={user}
          acceptOffer={handleAcceptOffer}
        />
      )
      break
    case "ownlease":
      component = (
        <OwnLeaseOffer
          offer={data}
          user={user}
          acceptOffer={handleAcceptOffer}
        />
      )
      break
    case "uown":
      component = (
        <UownOffer offer={data} user={user} acceptOffer={handleAcceptOffer} />
      )
      break
    case "flexshopper":
    case "flexshopper-external":
      component = (
        <FlexshopperOffer
          offer={data}
          user={user}
          acceptOffer={handleAcceptOffer}
        />
      )
      break
    case "citi":
      component = <CitiOffer offer={data} />
      break
    case "paypal":
      component = <PaypalOffer offer={data} />
      break
    default:
      component = (
        <SingleOfferCard offer={data} user={user} acceptButton={acceptButton} />
      )
  }

  return (
    <>
      {data?.status === "offered" &&
        ["finwise", "americanfirst"].includes(data.originator.key) && (
          <AffFinwiseSummaryModal
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )}

      {merchantId !== "trek" &&
        ![
          "finwise",
          "americanfirst",
          "ownlease",
          "flexshopper",
          "flexshopper-external",
          "citi",
        ].includes(data.originator.key) && (
          <OfferSummary
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )}

      {data.errors?.map((e) => (
        <Alert
          message={e}
          key={e}
          type="error"
          showIcon
          closable
          style={{ marginBottom: 5 }}
        />
      ))}
      <Row
        justify="center"
        align="middle"
        style={{
          marginLeft: 0,
          textAlign: "center",
          marginTop: 10,
          marginRight: 0,
        }}
      >
        {component}
      </Row>
      {/* <Modal
        visible={isOpenConfirmModal}
        title={"Select new product"}
        footer={
          <Row justify="end">
            <Button
              type="secondary"
              onClick={() => setIsOpenConfirmModal(false)}
            >
              No - Continue
            </Button>
            <Button type="primary" onClick={selectProduct}>
              Yes
            </Button>
          </Row>
        }
      >
        <div>Do you want to select new product?</div>
        <div style={{ marginTop: 15 }}>
          <strong>Current Product</strong>
        </div>
        <ProductCard item={lead?.product} />
      </Modal> */}
    </>
  )
}

export default OfferPage
