import _ from "underscore"

import api from "./api"
import { getOffer, acceptOffer } from "../hooks/requests"
import sleep from "../utils/sleep"
import { API_URL } from "../utils/constants"

export const fetchOffer = async (offerId) => {
  console.log("Fetching Offer:", offerId)
  try {
    const response = await api(getOffer(offerId))
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "offer", id, status }, "*")
    return { success: true, data, error: null }
  } catch (err) {
    console.error("Error Fetching Offer:", err, err?.response)
    return { success: false, offer: null, error: err }
  }
}

export const applyAndWait = async (
  offerId,
  offerData = { address: {}, bank_account: {}, debit_card: {}, income: {} }
) => {
  console.log("Applying for Offer:", offerId)
  try {
    let count = 0
    let offer = await fetchOffer(offerId)
    if (offer && offer.data.actions.apply) {
      offer = await submitOfferApply(offerId, offerData)
      console.log("offer is what", offer)
      if (!offer.success) {
        return offer
      }
    }

    while (offer && offer.data.status === "applying") {
      if (count >= 60) {
        return {
          success: false,
          data: offer.data,
          error: "We were unable to complete the request, please try again.",
        }
      }
      await sleep(1000)
      offer = await fetchOffer(offerId)
      count += 1
    }

    // An offer apply can revert from applying -> offered
    if (offer.data.status === "offered") {
      // TODO: Use offer.data.errors if available
      return {
        success: false,
        data: offer.data,
        error: "We were unable to complete the request, please try again.",
      }
    }

    return { success: true, offer: offer.data }
  } catch (err) {
    console.error("Error Applying for Offer:", err, err?.response)
    return { success: false, error: err }
  }
}

export const sendCitizensOtp = async (offerId, channel) => {
  console.log("Sending Citizens OTP", offerId)
  try {
    const response = await api.post(
      `${API_URL}/v1/offers/${offerId}/send-otp/`,
      { channel }
    ) // , {}, { headers: authHeader() });
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error Sending Citizens OTP:", err, err?.response)
    return { success: false, error: err.response }
  }
}

export const validateCitizensOtp = async (offerId, channel, code) => {
  console.log("Validating Citizens OTP:", offerId)
  try {
    const response = await api.post(
      `${API_URL}/v1/offers/${offerId}/validate-otp/`,
      { channel, code }
    ) // , {}, { headers: authHeader() });
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error Validating Citizens OTP:", err, err?.response)
    return { success: false, error: err.response }
  }
}

export const submitOfferApply = async (
  offerId,
  applyFields = { address: {}, bank_account: {}, debit_card: {}, income: {} }
) => {
  console.log("Submitting Offer Apply:", offerId)
  try {
    const applyResponse = await api(acceptOffer(offerId, applyFields))
    return { success: true, data: applyResponse.data, error: null }
  } catch (err) {
    console.error("Error Submitting Offer Apply:", err, err?.response)
    return { success: false, offer: null, error: err }
  }
}

export const prepareApplication = async (values) => {
  const offerObj = JSON.parse(JSON.stringify(values))
  console.log("i got values", values)

  if (_.contains(_.keys(offerObj), "income")) {
    if (_.contains(_.keys(offerObj.income), "net_monthly_income")) {
      if (typeof offerObj.income.net_monthly_income === "string") {
        offerObj.income.net_monthly_income = parseInt(
          offerObj.income.net_monthly_income.replace(/\D/g, "")
        )
      }
    }
    if (_.contains(_.keys(offerObj.income), "last_pay_date")) {
      offerObj.income.last_pay_date =
        offerObj.income.last_pay_date.split("T")[0]
    }
    if (_.contains(_.keys(offerObj.income), "next_pay_date")) {
      offerObj.income.next_pay_date =
        offerObj.income.next_pay_date.split("T")[0]
    }
  }
  if (_.contains(_.keys(offerObj), "debit_card")) {
    if (_.contains(_.keys(offerObj.debit_card), "expiry_month")) {
      offerObj.debit_card.expiry_month = parseInt(
        offerObj.debit_card.expiry_month
      )
    }
  }

  return offerObj
}

export const getSecurePaymentDebitCard = async (offerId) => {
  console.log("Fetching SecurePayment Debit Card")
  try {
    const response = await api.get(
      `${API_URL}/v1/offers/${offerId}/securepayment-debit-card/`
    )
    return { success: true, data: response.data }
  } catch (err) {
    console.error(
      "Error Fetching SecurePayment Debit Card:",
      err,
      err?.response
    )
    return { success: false, error: err.response }
  }
}

export const getSdkParams = async (offerId) => {
  console.log("Fetching Citi SDK Params:", offerId)
  try {
    const response = await api.get(
      `${API_URL}/v1/offers/${offerId}/sdk-params/`
    )
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error Fetching Citi SDK Params:", err, err?.response)
    return { success: false, error: err.response }
  }
}

export const validateCitiSdkAuth = async (offerId, data, status) => {
  console.log("Validating Citi SDK Auth:", offerId)
  try {
    const response = await api.post(
      `${API_URL}/v1/offers/${offerId}/sdk-auth/`,
      { data, status }
    )
    return { success: true, data: response.data }
  } catch (err) {
    return { success: false, error: err.response }
  }
}
export const checkOutSdk = async (
  offerId,
  status = null,
  data,
  token = null
) => {
  console.log("Checking Out Citi SDK:", offerId)
  try {
    const response = await api.post(
      `${API_URL}/v1/offers/${offerId}/sdk-checkout/`,
      {
        data,
        status,
        token,
      }
    )
    return { success: true, data: response.data }
  } catch (err) {
    return { success: false, error: err.response }
  }
}
