import React, { useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Collapse,
  Drawer,
  Row,
  Col,
  List,
  Typography,
  Button,
  Badge,
  Dropdown,
  Card,
} from "antd"

import {
  FileTextOutlined,
  LogoutOutlined,
  AuditOutlined,
  FileDoneOutlined,
  MenuOutlined,
  UserOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons"
import { getTheme } from "../../helpers/brand.helper"
import helpLists from "../../utils/helpLists"
import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"
import { isAuthenticated } from "../../services/auth-helper"
import { ProductCard } from "../cards"

const { Panel } = Collapse

function BasicMenu(props) {
  const { goBack } = useHistory()
  const { menuLogo, brandLabel, isMobile, isShowMenu } = useTheme()
  const {
    appsMenu,
    merchantId,
    lead,
    loan,
    order,
    cartsList,
    cartCount,
    cartTotal,
    updateCartList,
    removeCartList,
    isCartDisabled,
    merchant,
  } = useApply()

  const theme = getTheme()
  const helpList = helpLists[theme?.title]
  const [showMenu, setShowMenu] = useState(false)

  const loggedInData = [
    {
      label: "Apps",
      url: "/apps",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Accounts",
      url: "/loans",
      icon: <AuditOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: theme?.label,
      url: `${theme?.url}?token=${sessionStorage.getItem("jwt")}`,
      icon: <HomeOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Logout",
      url: "/logout",
      icon: <LogoutOutlined style={{ paddingRight: 5 }} />,
    },
  ]

  const data = [
    {
      label: "Apply",
      url: "/",
      icon: <FileDoneOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Login",
      url: "/login",
      icon: <UserOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: theme?.label,
      url: `${theme?.url}`,
      icon: <HomeOutlined style={{ paddingRight: 5 }} />,
    },
  ]

  const shopifyChkBtn = useMemo(() => {
    if (!["magento2", "shopify", "snipcart"].includes(lead?.channel))
      return <></>
    if (
      loan?.status === "declined" ||
      loan?.status === "review" ||
      loan?.status === "funded" ||
      order?.status === "approved" ||
      order?.status === "shipped" ||
      order?.status === "paid"
    ) {
      return (
        <Button
          onClick={() => window.location.replace(lead?.actions?.redirect)}
          style={{ borderRadius: 7, fontSize: isMobile ? 10 : 13 }}
          type="primary"
        >
          Continue to Check Out
        </Button>
      )
    }
    return (
      <Button
        onClick={() => window.location.replace(lead?.actions?.cancel)}
        style={{ borderRadius: 7, fontSize: isMobile ? 10 : 13 }}
        type="primary"
      >
        Cancel Check Out
      </Button>
    )
  }, [lead, loan, isMobile, order])

  const cartBtn = useMemo(() => {
    if (merchant?.selectable_product) {
      return (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Card
              bodyStyle={{
                paddingBottom: 10,
                paddingRight: 10,
                paddingLeft: 10,
                boxShadow: "rgb(10 9 9 / 24%) 0px 4px 6px",
              }}
              style={{
                width: 300,
                textAlign: "center",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Row align="middle" justify="space-between">
                <div style={{ fontWeight: 600, fontSize: 20 }}>Cart Total</div>
                <div style={{ fontWeight: 600, fontSize: 20 }}>
                  ${Number(cartTotal).toLocaleString()}
                </div>
              </Row>
              {cartsList.length > 0 &&
                cartsList.map((cart, index) => (
                  <ProductCard
                    key={index}
                    item={cart}
                    isQTY={!isCartDisabled}
                    selectProduct={updateCartList}
                    removeProduct={!isCartDisabled && removeCartList}
                  />
                ))}
              <Button type="primary" onClick={() => goBack()}>
                View Cart
              </Button>
            </Card>
          }
        >
          <Badge count={cartCount} offset={[0, 5]} size="small">
            <ShoppingCartOutlined
              style={{
                fontSize: 25,
                cursor: "pointer",
              }}
            />
          </Badge>
        </Dropdown>
      )
    }
    // eslint-disable-next-line
  }, [cartsList])

  return (
    <>
      <Row
        style={{
          margin: 0,
          paddingLeft: 5,
          paddingRight: 10,
          textAlign: "center",
        }}
        justify="center"
      >
        <Col>
          {isShowMenu && (
            <MenuOutlined
              style={{ fontSize: 18, padding: 10 }}
              onClick={() => setShowMenu(!showMenu)}
            />
          )}
        </Col>
        <Col
          style={
            ["magento2", "shopify", "snipcart"].includes(lead?.channel)
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }
              : { textAlign: "center" }
          }
          flex="auto"
        >
          <Link to="/">
            <img
              height={
                ["magento2", "shopify", "snipcart"].includes(lead?.channel) &&
                isMobile
                  ? 22
                  : 30
              }
              src={menuLogo}
              alt="platform"
            />
          </Link>
        </Col>
        <Col>
          {shopifyChkBtn}
          {cartBtn}
        </Col>
      </Row>
      <Drawer
        placement="left"
        visible={showMenu}
        closeIcon={null}
        width={250}
        onClose={() => setShowMenu(false)}
      >
        <Row
          justify="center"
          style={{
            marginTop: 0,
            paddingTop: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <List
            header={
              <Row justify="center" style={{ paddingBottom: 10 }}>
                <img src={menuLogo} alt="app-logo" height={25} />
              </Row>
            }
            dataSource={isAuthenticated() ? loggedInData : data}
            style={{ textAlign: "center", width: "90%" }}
            renderItem={(item) => (
              <List.Item
                style={
                  item.label === "Apps" && !appsMenu
                    ? {
                        justifyContent: "start",
                        opacity: 0.4,
                        pointerEvents: "none",
                      }
                    : { justifyContent: "start" }
                }
              >
                <a href={item.url}>
                  <Typography.Text>
                    {item.icon}
                    {item.label}
                  </Typography.Text>
                </a>
              </List.Item>
            )}
          />
        </Row>
        {!(merchantId === "trek" || theme?.title === "citizens") && (
          <Row>
            <Collapse ghost>
              <Panel header="Links" key="1">
                <List
                  dataSource={helpList}
                  footer={
                    <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                      © {new Date().getFullYear()} {brandLabel} Inc.
                    </Typography.Text>
                  }
                  style={{ textAlign: "center" }}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        justifyContent: "start",
                        marginLeft: 10,
                      }}
                    >
                      <a href={item.url}>
                        <Typography.Text>
                          {item.icon}
                          {item.label}
                        </Typography.Text>
                      </a>
                    </List.Item>
                  )}
                />
              </Panel>
            </Collapse>
          </Row>
        )}
      </Drawer>
    </>
  )
}

export default BasicMenu
