import { useState } from "react"
import { Form, Input, Select, AutoComplete } from "formik-antd"
import { HomeOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import { MAPBOX_API } from "../../utils/constants"
import state from "../../utils/state.json"
import { ValidateAddressInput } from "../../utils/validation"

const AddressInput = ({
  disabledFields,
  autoTooltip,
  formik,
  hasFeedback,
  showValidateSuccess,
  required,
  placeholder,
  errorZipMessage,
  errorAddressMessage,
  errorCityMessage,
}) => {
  const [addresses, setAddresses] = useState([])
  const [isTooltipAdr1Visible, setIsTooltipAdr1Visible] = useState(false)
  const [isTooltipAdr2Visible, setIsTooltipAdr2Visible] = useState(false)

  const tooltipText =
    "Please input Apt, Suite, etc. before entering the unit number"

  const handleFocus = (value) => {
    if (value === "adr1") {
      setIsTooltipAdr1Visible(true)
    } else if (value === "adr2") {
      setIsTooltipAdr2Visible(true)
    }
  }

  const handleBlur = (value) => {
    if (value === "adr1") {
      setIsTooltipAdr1Visible(false)
    } else if (value === "adr2") {
      setIsTooltipAdr2Visible(false)
    }
  }

  const queryMapbox = async (query) => {
    if (query.length > 3) {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_API}&country=us`
      )
      const json = await response.json()

      const results = []
      const places = json.features

      if (places?.length > 0) {
        for (let i = 0; i < places.length; i++) {
          const place = places[i].place_name
          const index = place.lastIndexOf(",")
          const add = place.substring(0, index)
          results.push(renderItem(add, i))
        }
      }
      setAddresses(results)
    }
  }

  const validateZip = (value) => {
    return value && /^\d{5}(-\d{4})?$/.test(value) && value.length === 5
      ? undefined
      : errorZipMessage || "Please enter a valid zip"
  }

  const renderItem = (title, count) => ({
    value: title,
    key: count,
    label: (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <HomeOutlined style={{ paddingRight: 10 }} />
        {title}
      </div>
    ),
  })

  const handleAddressSelect = (option) => {
    const tokens = option.split(",")
    const stateZip = tokens[tokens.length - 1].split(" ")
    const zip = stateZip[stateZip.length - 1]
    const stateIndex = tokens[tokens.length - 1].indexOf(zip)

    formik.setFieldValue("address.street1", tokens[0])
    formik.setFieldValue("address.city", tokens[1].trim())

    const stateInput = tokens[tokens.length - 1].substring(0, stateIndex).trim()
    const selectedState = state.find(
      (s) => s.State.toLowerCase() === stateInput.toLowerCase()
    )

    formik.setFieldValue("address.state", selectedState?.Code || stateInput)
    formik.setFieldValue("address.zip", zip)
  }

  return (
    <>
      <Form.Item
        name="address.street1"
        hasFeedback={hasFeedback}
        showValidateSuccess={showValidateSuccess}
        required={required}
        label="Address"
        tooltip={{
          title: `A P.O. Box or rented mailbox cannot be used in the billing address. Special characters are also not allowed. Special characters include *, . "" : ; = @ & and double spaces`,
          visible: autoTooltip ? isTooltipAdr1Visible : undefined,
        }}
        validate={(value) => ValidateAddressInput(value, errorAddressMessage)}
      >
        <AutoComplete
          name="address.street1"
          size="large"
          placeholder={placeholder}
          disabled={disabledFields?.addressStreet1}
          options={addresses}
          onSelect={handleAddressSelect}
          onChange={queryMapbox}
          onFocus={() => handleFocus("adr1")}
          onBlur={() => handleBlur("adr1")}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address.street2"
            hasFeedback
            label="Apartment or Unit #"
            required={false}
            tooltip={{
              title: tooltipText,
              visible: autoTooltip ? isTooltipAdr2Visible : undefined,
            }}
            showValidateSuccess={false}
          >
            <Input
              name="address.street2"
              size="large"
              placeholder="Apt or Unit #"
              disabled={disabledFields?.addressStreet2}
              onFocus={() => handleFocus("adr2")}
              onBlur={() => handleBlur("adr2")}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address.city"
            label="City"
            validate={(value) =>
              value.length === 0
                ? errorCityMessage || "Please enter a valid city"
                : undefined
            }
            hasFeedback
            showValidateSuccess={false}
            required={false}
          >
            <Input
              name="address.city"
              size="large"
              placeholder="City"
              disabled={disabledFields?.addressCity}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address.state"
            label="State"
            validate={(value) =>
              value.length === 0 ? "Please enter a valid state" : undefined
            }
            hasFeedback
            showValidateSuccess={false}
            required={false}
          >
            <Select
              name="address.state"
              size="large"
              placeholder="State"
              disabled={disabledFields?.addressState}
            >
              {state.map((s) => (
                <Select.Option key={s.Code}>{s.State}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address.zip"
            label="Zip"
            validate={validateZip}
            hasFeedback
            showValidateSuccess={false}
            required={false}
          >
            <Input
              name="address.zip"
              size="large"
              inputMode="numeric"
              placeholder="Zip"
              disabled={disabledFields?.addressZip}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default AddressInput
