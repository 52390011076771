import React from "react"
import { Formik } from "formik"
import { Form, DatePicker } from "formik-antd"
import { Typography, Row, Col, Button } from "antd"
import {
  BankAccountInput,
  PayrollFrequencySelect,
  MoneyInput,
  DebitCardInput,
} from "../inputs"
import ComplianceAgree from "../compliance/ComplianceAgree"
import { validateFuture, validatePast } from "../../utils/validation"

const { Title, Text } = Typography

export default function OfferForm({
  values,
  submitText,
  onSubmit,
  offer,
  isSubmitted,
  setIsSubmitted,
}) {
  console.log("values come in offer is", values, offer)

  function renderIncome(formik) {
    if (!values.hasOwnProperty("income")) return null

    return (
      <>
        <Title style={{ textAlign: "left" }} level={5}>
          Income Information
        </Title>
        <Text>Additional income information is needed by this provider</Text>
        <br />
        <br />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <MoneyInput
              name="income.net_monthly_income"
              min={1000}
              hasFeedback
              label="Net Monthly Income"
              tooltip="Your monthly income after taxes."
              formik={formik}
              placeholder="$ Net Monthly Income"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <PayrollFrequencySelect />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="income.last_pay_date"
              label="Last Pay Date"
              hasFeedback
              validate={validatePast}
            >
              <DatePicker
                name="income.last_pay_date"
                size="large"
                // defaultValue={moment(moment().subtract(1, 'days'), 'YYYY-MM-DD')}
                format="MM/DD/YYYY"
                style={{ width: "100%" }}
                label="Last Pay Date"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="income.next_pay_date"
              label="Next Pay Date"
              hasFeedback
              validate={validateFuture}
            >
              <DatePicker
                name="income.next_pay_date"
                size="large"
                format="MM/DD/YYYY"
                // defaultValue={moment(moment().add(1, 'days'), 'YYYY-MM-DD')}
                style={{ width: "100%" }}
                label="Next Pay Date"
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  function renderDebitCard(formik) {
    if (!values.hasOwnProperty("debit_card")) return null

    return (
      <>
        <Title style={{ textAlign: "left" }} level={5}>
          Debit Card Information
        </Title>
        <Text>
          This order requires a first payment. You will not be charged until
          your lease agreement has been signed.
        </Text>
        <br />
        <Text>
          The debit card will be used to make payments on this account. This can
          be changed later. The card verification process requires a temporary
          hold of up to 4 cents to be placed on your card.
        </Text>
        <br />
        <br />
        <Text strong>Important: Must not be a pre-paid debit card</Text>
        <br />
        <br />
        <DebitCardInput formik={formik} />
      </>
    )
  }

  function renderBankAccount(formik) {
    if (!values.hasOwnProperty("bank_account")) return null

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title style={{ textAlign: "left" }} level={5}>
            Bank Account Information
          </Title>
          <Text>
            This should be your primary bank account. Bank account information
            is used for your identity and income verification purposes
          </Text>
          <br />
          <br />
          <BankAccountInput formik={formik} />
        </Col>
      </Row>
    )
  }

  function renderCompliance() {
    switch (offer.originator.key) {
      case "finwise":
      case "americanfirst":
        return <ComplianceAgree offer={offer} />
      default:
        return null // <ComplianceAgree offer={offer} />
    }
  }

  const handleAppy = (formik) => {
    if (Object.keys(formik.errors).length === 0) {
      setIsSubmitted(true)
      formik.handleSubmit()
    }
  }

  return (
    <Formik initialValues={values} onSubmit={onSubmit}>
      {(formik) => (
        <Form layout="vertical">
          {renderIncome(formik)}
          {renderBankAccount(formik)}
          {renderDebitCard(formik)}
          {renderCompliance()}
          <Row justify="center" style={{ marginTop: 20 }}>
            <Button
              type="primary"
              onClick={() => handleAppy(formik)}
              disabled={isSubmitted}
            >
              {submitText}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
